import router from "@/router";
import axios from "axios";
import { EventHelper } from "@/helpers/eventHelper";

const dafisSessionTokenKey = "dafis-session-token";
const dafisReloginTokenKey = "dafis-relogin-token";
let reloginRequest;

class SessionHelpers {
  async login(email, password, rememberMe = false) {
    if (email && email !== "" && password && password !== "") {
      try {
        const encodedPassword = btoa(("fjhdhsTG7fmelklskf9" + password + "pol,wsmdWGOnbw7uj").split("").reverse().join(""));
        const response = await axios.post(`${import.meta.env.VITE_DAFIS_API_URL}/UserFunction`, {
          email,
          password: encodedPassword
        });

        if (response.status === 200 && response.data) {
          sessionStorage.setItem(dafisSessionTokenKey, response.data.token);
          if (rememberMe) {
            localStorage.setItem(dafisReloginTokenKey, response.data.reloginToken);
          } else {
            sessionStorage.setItem(dafisReloginTokenKey, response.data.reloginToken);
          }

          EventHelper.emit("userSessionChanged", this.isUserAuthenticated);
          return true;
        } else {
          EventHelper.emit("userSessionChanged", this.isUserAuthenticated);
          return false;
        }
      } catch (error) {
        switch (error.message) {
          case "Network Error":
            // Handle network error
            break;

          default:
            // Handle error
            break;
        }
        // Handle error
        EventHelper.emit("userSessionChanged", this.isUserAuthenticated);
        return false;
      }
    }
    EventHelper.emit("userSessionChanged", this.isUserAuthenticated);
    return false;
  }

  async relogin() {
    if (reloginRequest) {
      return reloginRequest;
    }

    // eslint-disable-next-line no-async-promise-executor
    reloginRequest = new Promise(async resolve => {
      const reloginTokenFromLocalStorage = localStorage.getItem(dafisReloginTokenKey);
      const reloginTokenFromSessionStorage = sessionStorage.getItem(dafisReloginTokenKey);

      if (reloginTokenFromLocalStorage == null && reloginTokenFromSessionStorage == null) {
        this.logout();
        resolve(false);
        return;
      }

      try {
        const response = await axios.post(`${import.meta.env.VITE_DAFIS_API_URL}/UserFunction/relogin`, {
          reloginToken: reloginTokenFromLocalStorage ? reloginTokenFromLocalStorage : reloginTokenFromSessionStorage
        });

        if (response.status === 200 && response.data) {
          sessionStorage.setItem(dafisSessionTokenKey, response.data.token);

          if (reloginTokenFromLocalStorage) {
            localStorage.setItem(dafisReloginTokenKey, response.data.reloginToken);
          } else {
            sessionStorage.setItem(dafisReloginTokenKey, response.data.reloginToken);
          }

          EventHelper.emit("userSessionChanged", this.isUserAuthenticated);
          resolve(true);
        } else {
          this.logout();
          resolve(false);
        }
      } catch (error) {
        switch (error.message) {
          case "Network Error":
            // Handle network error
            break;

          default:
            // Handle error
            break;
        }
        // Handle error
        this.logout();
        resolve(false);
      }
    });

    reloginRequest.finally(() => {
      reloginRequest = null;
    });

    return reloginRequest;
  }

  logout() {
    sessionStorage.removeItem(dafisReloginTokenKey);
    sessionStorage.removeItem(dafisSessionTokenKey);
    localStorage.removeItem(dafisReloginTokenKey);
    localStorage.removeItem(dafisSessionTokenKey);
    EventHelper.emit("userSessionChanged", this.isUserAuthenticated);

    if (router.currentRoute.value?.meta?.authenticationNeeded) {
      router.push({ name: "login" });
    }
  }

  get accessToken() {
    return sessionStorage.getItem(dafisSessionTokenKey);
  }

  get isUserAuthenticated() {
    return sessionStorage.getItem(dafisSessionTokenKey) !== null;
  }
}

export const SessionHelper = new SessionHelpers();
