export class Product {
  id = null;
  name = "";
  description = "";
  unitPrice = 0;
  unit = "";
  isActive = true;
  isDeleted = false;

  constructor(dbProduct) {
    if (dbProduct) {
      this.id = dbProduct.id;
      this.name = dbProduct.name;
      this.description = dbProduct.description;
      this.unitPrice = dbProduct.unitPrice;
      this.unit = dbProduct.unit;
      this.isActive = dbProduct.isActive;
      this.isDeleted = !!dbProduct.isDeleted;
    }
  }
}
