import { createI18n } from "vue-i18n";

let i18n;

export const SUPPORT_LOCALES = ["da", "en"];

export async function setI18nLanguage(locale) {
  if (i18n.mode === "legacy") {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }

  const localMessages = await loadLocaleMessages(locale);
  const externalMessages = await loadExternalLocaleMessages(locale);
  const mergedMessages = Object.assign(localMessages, externalMessages);
  i18n.global.setLocaleMessage(locale, mergedMessages);
  saveLocale(locale);
  document.querySelector("html").setAttribute("lang", locale);
}

async function loadLocaleMessages(locale) {
  const messages = await import(`@/locales/${locale}.json`);
  const helpMessages = await import(`@/locales/${locale}-help.json`);
  return Object.assign(messages.default, helpMessages.default);
}

async function loadExternalLocaleMessages(locale) {
  return fetch(`https://dafislocales.blob.core.windows.net/dafis/${locale}-help.json`)
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })
    .catch(() => {
      return {};
    });
}

export default async function setupI18n() {
  if (!i18n) {
    const locale = getLocale() || import.meta.env.VITE_DEFAULT_LOCALE;

    i18n = createI18n({
      legacy: false,
      locale,
      fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE
    });

    await setI18nLanguage(locale);
  }
  return i18n;
}

function saveLocale(locale) {
  localStorage.setItem("lang", locale);
}

export function getLocale() {
  return localStorage.getItem("lang") === "null" ? null : localStorage.getItem("lang");
}
