export class Job {
  id = null;
  contactId = null;
  name = null;
  description = null;
  isActive = true;
  jobNo = -1;
  reference = "";
  contactReference = "";
  budget = null;

  constructor(dbJob) {
    if (dbJob) {
      this.id = dbJob.id;
      this.contactId = dbJob.contactId;
      this.name = dbJob.name;
      this.description = dbJob.description;
      this.isActive = dbJob.isActive;
      this.jobNo = dbJob.jobNo;
      this.reference = dbJob.reference;
      this.contactReference = dbJob.contactReference;
      this.budget = dbJob.budget;
    }
  }
}
