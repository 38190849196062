export default class User {
  organizationId = null;
  userId = null;
  email = null;
  password = null;
  userRole = null;
  subscriptionType = null;
  reloginToken = null;

  constructor(dbRegistration) {
    if (dbRegistration) {
      this.organizationId = dbRegistration.organizationId;
      this.userId = dbRegistration.userId;
      this.email = dbRegistration.email;
      this.password = dbRegistration.password;
      this.userRole = dbRegistration.userRole;
      this.subscriptionType = dbRegistration.subscriptionType;
      this.reloginToken = dbRegistration.reloginToken;
    }
  }

  static parseUserFromAccessToken(parsedToken) {
    const user = new User();
    user.organizationId = parsedToken.aud;
    user.userId = parsedToken.subId;
    user.email = parsedToken.sub;
    user.userRole = parsedToken.role;
    user.subscriptionType = parsedToken.license;
    user.reloginToken = parsedToken.reloginToken;

    return user;
  }
}

export const SUBSCRIPTION_TYPE = Object.freeze({
  NONE: 0,
  TRYOUT: 1,
  FREE: 2,
  PRO: 4
});

export const USER_ROLE = Object.freeze({
  OWNER: 1,
  SUPERUSER: 2,
  USER: 4
});
