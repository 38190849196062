export class Contact {
  id = null;
  name = null;
  isActive = true;
  isDeleted = false;

  constructor(dbContact) {
    if (dbContact) {
      this.id = dbContact.id;
      this.name = dbContact.name;
      this.isActive = dbContact.isActive;
      this.isDeleted = !!dbContact.isDeleted;
    }
  }
}
