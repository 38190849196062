import { defineStore } from "pinia";
import { dafisApi } from "@/helpers/api/dafisApi";
import { Job } from "@/models/job";
import { useContactStore } from "@/stores/contactStore";

export const useJobStore = defineStore("jobStore", {
  state: () => ({
    jobsState: []
  }),

  actions: {
    async initialize() {},

    async loadJobs() {
      if (this.jobsState.length === 0) {
        await this.reloadJobs();
      }
    },

    async reloadJobs() {
      const response = await dafisApi().get("/job");
      this.jobsState = response.data.map(job => new Job(job));
    },

    async saveJob(job) {
      if (!job.id) {
        const response = await dafisApi().post("/job", job);
        const savedJob = response.data;
        this.jobs.push(savedJob);
        return savedJob;
      } else {
        await dafisApi().patch(`/job/${job.id}`, job);

        const index = this.jobs.findIndex(j => j.id === job.id);
        this.jobsState[index] = job;
        return job;
      }
    },

    reloadMinimumData() {
      this.reloadJobs();
    }
  },

  getters: {
    jobs(state) {
      const contactStore = useContactStore();
      const jobsWithContact = state.jobsState.map(job => {
        const jobClone = new Job(job);
        jobClone.contact = contactStore.contact(job.contactId);
        return jobClone;
      });
      return jobsWithContact;
    },

    job() {
      return id => {
        return this.jobs.find(job => job.id === id);
      };
    },

    jobsForContact() {
      return contactId => {
        return this.jobs.filter(job => job.contactId === contactId).sort((a, b) => a.name.localeCompare(b.name));
      };
    },

    jobsActiveForContact() {
      return contactId => {
        return this.jobs
          .filter(job => job.contactId === contactId && job.isActive)
          .sort((a, b) => a.name.localeCompare(b.name));
      };
    },

    filteredJobs() {
      return viewConfiguration => {
        // Filter on status
        return this.jobs.filter(
          job =>
            (viewConfiguration.isActiveJobsVisible && job.isActive) ||
            (viewConfiguration.isInactiveJobsVisible && !job.isActive)
        );
      };
    },

    searchedJobs() {
      return viewConfiguration => {
        if (!viewConfiguration.searchTerm) {
          return this.filteredJobs(viewConfiguration);
        }

        const searchTerm = viewConfiguration.searchTerm.toLowerCase();

        return this.filteredJobs(viewConfiguration).filter(job => {
          return (
            job.jobNo.toString().toLowerCase().includes(searchTerm) ||
            job.name.toLowerCase().includes(searchTerm) ||
            job.description.toLowerCase().includes(searchTerm) ||
            job.contact?.name?.toLowerCase().includes(searchTerm)
          );
        });
      };
    },

    sortedAndFilteredJobs() {
      return viewConfiguration => {
        if (viewConfiguration.sortProperty.indexOf(".") > -1) {
          const split = viewConfiguration.sortProperty.split(".");
          return [...this.searchedJobs(viewConfiguration)].sort((a, b) => {
            try {
              if (a[split[0]][split[1]] < b[split[0]][split[1]]) {
                return viewConfiguration.sortDirection === "asc" ? -1 : 1;
              }
              if (a[split[0]][split[1]] > b[split[0]][split[1]]) {
                return viewConfiguration.sortDirection === "asc" ? 1 : -1;
              }
              return 0 || a.createdDateTime > b.createdDateTime;
            } catch {
              return 0;
            }
          });
        } else {
          return [...this.searchedJobs(viewConfiguration)].sort((a, b) => {
            if (a[viewConfiguration.sortProperty] < b[viewConfiguration.sortProperty]) {
              return viewConfiguration.sortDirection === "asc" ? -1 : 1;
            }
            if (a[viewConfiguration.sortProperty] > b[viewConfiguration.sortProperty]) {
              return viewConfiguration.sortDirection === "asc" ? 1 : -1;
            }
            return 0 || a.createdDateTime > b.createdDateTime;
          });
        }
      };
    }
  }
});
