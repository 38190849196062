import Rollbar from "rollbar";
import config from "@/../rollbar.config";

config.ignoredMessages = ["Request failed with status code 401"];

const rollbar = !import.meta.env.DEV ? new Rollbar(config) : null;

export default {
  install(app) {
    if (!import.meta.env.DEV) {
      app.config.errorHandler = (error, vm, info) => {
        rollbar.error(error, { info });
        if (app.config.devtools) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      };
      app.provide("rollbar", rollbar);

      app.config.warnHandler = msg => {
        if (app.config.devtools) {
          // eslint-disable-next-line no-console
          console.warn(msg);
        }
      };
    }
  }
};
