export const formatNumber = {
  mounted(el) {
    let cursorPosition = 0;
    let startLength = 0;

    el.onkeydown = evt => {
      if (
        evt.key === "Backspace" ||
        evt.key === "Delete" ||
        evt.key === "ArrowLeft" ||
        evt.key === "ArrowRight" ||
        evt.key === "Tab"
      ) {
        return true;
      }

      if (evt.key === "Escape") {
        evt.target.value = "";
      }

      if (!evt.key.match(/[0-9.,]/gm)) {
        return false;
      }

      if (evt.target.value.match(/[0-9.]*,[0-9]{3,4}/gm)) {
        return false;
      }

      if (evt.target.value.match(/[0-9.]*,[0-9]{2}/gm) && evt.target.selectionStart > evt.target.value.indexOf(",")) {
        return false;
      }

      return true;
    };

    el.oninput = evt => {
      cursorPosition = evt.target.selectionStart;
      startLength = evt.target.value.length;
    };

    el.onkeyup = evt => {
      if (evt.key.match(/[0-9.,]/gm)) {
        const endLength = evt.target.value.length;
        const newPosition = cursorPosition + (endLength - startLength);
        evt.target.selectionStart = newPosition;
        evt.target.selectionEnd = newPosition;
      }

      if (evt.key === "Backspace" || evt.key === "Delete") {
        const endLength = evt.target.value.length;
        let newPosition = cursorPosition - (startLength - endLength);

        if (evt.target.value[newPosition] == ".") {
          newPosition++;
        }

        evt.target.selectionStart = newPosition;
        evt.target.selectionEnd = newPosition;
      }
    };
  }
};
