<script setup>
import { defineAsyncComponent, reactive, onMounted, onUnmounted } from "vue";
import { EventHelper } from "@/helpers/eventHelper";
import { RouterView } from "vue-router";
import { SessionHelper } from "@/helpers/sessionHelper";
import { useJobStore } from "@/stores/jobStore";
import { useContactStore } from "@/stores/contactStore";
import { useProductStore } from "@/stores/productStore";

const createMenu = defineAsyncComponent(() => import("@/components/navigation/createMenu.vue"));
const mainMenu = defineAsyncComponent(() => import("@/components/navigation/mainMenu.vue"));

const state = reactive({
  isUserAuthenticated: SessionHelper.isUserAuthenticated
});

onMounted(async () => {
  if (SessionHelper.isUserAuthenticated) {
    useContactStore();
    useProductStore();
    useJobStore();
  }

  EventHelper.on("userSessionChanged", isUserAuthenticated => {
    state.isUserAuthenticated = isUserAuthenticated;

    if (isUserAuthenticated) {
      useJobStore();
      useContactStore();
    }
  });
});

onUnmounted(() => {
  EventHelper.off("userSessionChanged");
});
</script>

<template>
  <header class="h-28 bg-white block top-0 right-0 w-full z-50" :style="{ height: state.isUserAuthenticated ? 0 + 'px' : '' }">
    <transition name="fade">
      <div v-if="!state.isUserAuthenticated" class="dafis-logo">
        <figure class="h-full my-0">
          <img src="@/assets/graphics/dafis-logo-color.svg" class="h-full" />
        </figure>
      </div>
    </transition>
  </header>

  <create-menu v-if="state.isUserAuthenticated"></create-menu>

  <main class="my-8 md:mt-24 px-2 xl:px-8 bg-background" :class="{ 'ml-40 xl:ml-52': state.isUserAuthenticated }">
    <RouterView />
  </main>

  <transition name="slide-left">
    <main-menu v-if="state.isUserAuthenticated"></main-menu>
  </transition>
</template>

<style scoped>
.dafis-logo {
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: 20px;
  @apply h-20 z-50;
}
</style>
