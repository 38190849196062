import { defineStore } from "pinia";
import { dafisApi } from "@/helpers/api/dafisApi";
import { Contact } from "@/models/contact";

export const useContactStore = defineStore("contactStore", {
  state: () => ({
    contacts: []
  }),

  actions: {
    async loadContacts() {
      if (this.contacts.length === 0) {
        this.reloadContacts();
      }
    },

    async reloadContacts() {
      const response = await dafisApi().get("/contact");
      this.contacts = response.data.map(contact => new Contact(contact));
    },

    reloadMinimumData() {
      this.reloadContacts();
    }
  },

  getters: {
    contactsOrderedByName(state) {
      return state.contacts.sort((a, b) => a.name.localeCompare(b.name));
    },

    contact(state) {
      return id => state.contacts.find(contact => contact.id === id);
    },

    activeContacts(state) {
      const activeContacts = state.contacts.filter(contact => contact.isActive && !contact.isDeleted);
      activeContacts.sort((a, b) => a.name.localeCompare(b.name));
      return activeContacts;
    }
  }
});
