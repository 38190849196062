import { createPinia, getActivePinia } from "pinia";
import runInitialize from "./plugins/runInitialize";
import resetStore from "./plugins/resetStore";
import { EventHelper } from "@/helpers/eventHelper";

export function initPinia() {
  const pinia = createPinia();
  pinia.use(runInitialize);
  pinia.use(resetStore);

  EventHelper.on("userSessionChanged", isUserAuthenticated => {
    if (!isUserAuthenticated) {
      resetPinia();
    } else {
      reloadMinimumData();
    }
  });

  return pinia;
}

export function resetPinia() {
  const pinia = getActivePinia();
  pinia._s.forEach(store => {
    store.resetStore();
  });
}

export async function reloadMinimumData() {
  const pinia = getActivePinia();
  const promises = [];
  const userStore = pinia._s.get("userStore");
  if (userStore && userStore.reloadMinimumData) {
    promises.push(userStore.reloadMinimumData());
  }

  const contactStore = pinia._s.get("contactStore");
  if (contactStore && contactStore.reloadMinimumData) {
    promises.push(contactStore.reloadMinimumData());
  }

  const productStore = pinia._s.get("productStore");
  if (productStore && productStore.reloadMinimumData) {
    promises.push(productStore.reloadMinimumData());
  }

  await Promise.all(promises);

  const jobStore = pinia._s.get("jobStore");
  if (jobStore && jobStore.reloadMinimumData) {
    await jobStore.reloadMinimumData();
  }

  const registrationStore = pinia._s.get("registrationStore");
  if (registrationStore && registrationStore.reloadMinimumData) {
    await registrationStore.reloadMinimumData();
  }

  // Reload contacts and products after 10 seconds to ensure newly synchronized from server is loaded
  setTimeout(() => {
    const contactStore = pinia._s.get("contactStore");
    if (contactStore && contactStore.reloadMinimumData) {
      contactStore.reloadMinimumData();
    }

    const productStore = pinia._s.get("productStore");
    if (productStore && productStore.reloadMinimumData) {
      productStore.reloadMinimumData();
    }
  }, 10000);
}
