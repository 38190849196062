export default function resetStore({ store }) {
  const initialState = JSON.parse(JSON.stringify(store.$state));
  store.resetStore = () => {
    Object.keys(initialState).forEach(key => {
      if (!initialState.preservableStates?.includes(key)) {
        store.$state[key] = JSON.parse(JSON.stringify(initialState[key]));
      }
    });
  };
}
