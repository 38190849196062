import { defineStore } from "pinia";
import { dafisApi } from "@/helpers/api/dafisApi";
import { Product } from "@/models/product";

export const useProductStore = defineStore("productStore", {
  state: () => ({
    products: []
  }),

  actions: {
    async loadProducts() {
      if (this.products.length === 0) {
        this.reloadProducts();
      }
    },

    async reloadProducts() {
      const response = await dafisApi().get("/product");
      this.products = response.data.map(product => new Product(product));
    },

    reloadMinimumData() {
      this.reloadProducts();
    }
  },

  getters: {
    productsOrderedByName(state) {
      return state.products.sort((a, b) => a.name.localeCompare(b.name));
    },

    product(state) {
      return id => state.products.find(product => product.id === id);
    },

    activeProducts(state) {
      const products = state.products.filter(product => product.isActive && !product.isDeleted);
      products.sort((a, b) => a.name.localeCompare(b.name));
      return products;
    }
  }
});
