export default {
  accessToken: "a3293a2105c845babc401f56686adf71",
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: "dev",
    client: {
      javascript: {
        code_version: "1.0.0"
      }
    }
  }
};
