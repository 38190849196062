import { createApp } from "vue";
import { initPinia, reloadMinimumData } from "./stores";

import App from "./App.vue";
import router from "./router";
import RollbarPlugin from "@/helpers/rollbarHelper";

import i18n from "./helpers/i18nHelper";

import { formatNumber } from "@/directives/formatNumber";

import "./assets/main.css";

/**
 * Initialize the app and mount it
 */
async function initializeApp() {
  const app = createApp(App);

  if (!import.meta.env.DEV) {
    app.use(RollbarPlugin);
  }
  app.use(initPinia());

  app.use(await i18n());
  app.use(router);

  app.directive("format-number", formatNumber);

  app.mount("#app");
}

// Initialize app
initializeApp();

if (typeof document.hidden != "undefined") {
  var hidden = "hidden";
  var visibilityChange = "visibilitychange";

  document.addEventListener(visibilityChange, function () {
    if (!document[hidden]) {
      reloadMinimumData();
    }
  });
}
