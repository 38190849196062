import axios from "axios";
import { SessionHelper } from "@/helpers/sessionHelper";

let dafisApiInstance = null;

function initApi() {
  dafisApiInstance = axios.create({
    baseURL: import.meta.env.VITE_DAFIS_API_URL
  });

  dafisApiInstance.interceptors.request.use(config => {
    if (SessionHelper.accessToken || SessionHelper.relogin()) {
      config.headers.Authorization = `Bearer ${SessionHelper.accessToken}`;
    }

    return config;
  });

  dafisApiInstance.interceptors.response.use(undefined, async error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // Session ended - try to relogin
          if (await SessionHelper.relogin()) {
            error.config.headers["Authorization"] = `Bearer ${SessionHelper.accessToken}`;
            return axios.request(error.config);
          }
          break;

        default:
          break;
      }
    }
    return Promise.reject(error);
  });
}

export function dafisApi() {
  if (!dafisApiInstance) {
    initApi();
  }
  return dafisApiInstance;
}
